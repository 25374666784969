import VoteImage from '../assets/images/voto-obligatorio.jpeg';
import VoteModal from './VoteModal';
import Stats from './Stats';
import { useState, useEffect } from 'react';
import { checkToken } from '../utils';
import API from '../utils/api';
import Results from './Results';
export default function Home() {

    const [logged, setLogged] = useState(false);
    const [voted, setVoted] = useState(false);
    const [count, setCount] = useState(0);

    
    useEffect(() => {
        setLogged(checkToken())
    }, [])

    const handleVote = () => {
        setVoted(true)
        setCount(prevState => prevState + 1)
    }
    useEffect(() => {
        API.get('api/v1/constitution/index').then(res => {
            setCount(res.data.count)
        }).catch(err => {});
    }, [])
    return (
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="relative rounded-lg overflow-hidden">
            <div className="absolute inset-0">
              <img
                src={VoteImage}
                alt=""
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="relative bg-gray-900 bg-opacity-75 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
                <div className="relative max-w-3xl mx-auto flex flex-col items-center text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                    <span className="block sm:inline">Nueva constitución para Chile</span>
                    </h2>
                    <p className="mt-3 text-xl text-white">
                    El 4 de septiembre de 2022, se votara el Plebiscito Constitucional de Salida, instancia donde los ciudadanos podrán votar por un cambio de constitución para Chile. Deberán enfrentarse a una papeleta para votar Apruebo o Rechazo al texto constitucional propuesto por la Convención.
                    </p>
                    {logged || voted ? <div> 
                        <p className="mt-8 text-5xl text-white font-extrabold">
                        Gracias Por Votar!
                        </p>
                        <p className="mt-2 text-md text-white">
                        Se actualizaran los resultados todos los Lunes.    
                            </p></div> : <div className="mt-2">
                        <p className="mt-3 text-xl text-white">
                        ¿Que votaras tu?
                        </p>
                        
                        <VoteModal vote={1} onVote={handleVote}/>
                        <VoteModal vote={2} onVote={handleVote}/>
                    </div>}
                </div>
                <div className="mt-6 relative max-w-3xl mx-auto flex flex-col items-center text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                        <span className="block sm:inline">{count} votos registrados</span>
                    </h2>
                    {logged || voted ? <Results /> : null}
                </div>
            </div>
          </div>
        </div>
        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {logged || voted ? <Stats /> : null}
        </div> */}
      </div>
    )
  }