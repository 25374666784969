import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import API from '../utils/api'
import { LineChart, PieChart } from 'react-chartkick'
import 'chartkick/chart.js'

export default function Results(props) {
  const [open, setOpen] = useState(false)
  const [stats, setStats] = useState({date: '', approved: 0, disapproved: 0, total: 0})
  const cancelButtonRef = useRef(null)
  const formActive = false;
  useEffect(() => {
        getStats()
  }, [])
  const getStats = () => {
        API.get(`api/v1/constitution/stats`)
        .then(res => {
            setStats(res.data);
        })
        .catch(err => {
          
        });
  }
  return (
    <>
    
    <button
        onClick={() => setOpen(prevState => !prevState)}
        className={"mt-8 w-full block bg-blue-600 border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:text-gray-200 hover:bg-blue-800 sm:w-auto"}
    >
      Resultados!
    </button>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Actualizado el {stats.date}
                    </Dialog.Title>
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Apruebo: {stats.approved} ({stats.approved > 0 ? (stats.approved/stats.total)*100 : 0}%)
                    </Dialog.Title>
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Rechazo: {stats.disapproved} ({stats.disapproved > 0 ? (stats.disapproved/stats.total)*100 : 0}%)
                    </Dialog.Title>
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      <PieChart data={{"Apruebo": stats.approved, "Rechazo": stats.disapproved}} />
                    </Dialog.Title>
                  </div>
                  
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </button>
                  
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}