import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XCircleIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'
import API from '../utils/api'


export default function VoteModal(props) {
  const [open, setOpen] = useState(false)
  const [vote, setVote] = useState(props.vote)
  const cancelButtonRef = useRef(null)

  const navigate = useNavigate()
  const formActive = false;
  const newVote = (item) => {
        API.post(`api/v1/constitution/votes`, {votes: {vote: item}})
        .then(res => {
            localStorage.setItem("token", res.data);
            navigate.push("/");
        })
        .catch(err => {
          
        });
        props.onVote();
  }

  const handleVote = () => {
    setOpen(false)
    newVote(vote === 1 ? 'approve' : 'disapprove')
}
  return (
    <>
    {props.vote == 1 ? <button
        onClick={() => setOpen(prevState => !prevState)}
        className={"mt-8 w-full block bg-green-600 border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:bg-green-800 sm:w-auto"}
    >
     Apruebo!
    </button> : 
    <button
        onClick={() => setOpen(prevState => !prevState)}
        className={"mt-8 w-full block bg-red-600 border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:bg-red-800 sm:w-auto"}
    >
      Rechazo!
    </button>}
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  {vote == 1 ? 
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div> : 
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {vote == 1 ? 'Apruebo!' : 'Rechazo!'}
                    </Dialog.Title>
                  </div>
                  {formActive ? <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Nombre
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2 sm:mt-px sm:pt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Rut
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2 sm:mt-px sm:pt-2">
                      <input
                        type="text"
                        name="dni"
                        id="dni"
                        className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  </> : null}
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                    onClick={handleVote}
                  >
                    Votar
                  </button>
                  
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}