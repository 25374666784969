import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
axios.defaults.withCredentials = true

instance.interceptors.request.use(
    function(config) {
      const accessToken = localStorage.getItem("token");
      if (accessToken) {
        config.headers["access-token"] = accessToken;
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );


export default instance;
