 export const userSignedIn = () => {
  const token = localStorage.getItem("access-token");
  return token ? true : false;
}

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

export function checkToken() {
    if (localStorage.getItem("token")) {
        return true;
    } else {
        return false;
    }
}